import { ApiProvider } from '@/common/utils/ApiProvider';

import { ApiError } from './ApiError';
import { ApiRequestConfig } from './ApiRequestConfig';

const isOk = (res: Response) => res.status >= 200 && res.status < 400;

const getTimeZoneHeaders = () => {
  try {
    return {
      'x-timezone-id': Intl.DateTimeFormat().resolvedOptions().timeZone ?? ''
    };
  } catch {
    return {};
  }
};

const getTimeZoneOffsetHeaders = () => {
  try {
    let offset = new Date().getTimezoneOffset();
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
    // We need to invert the offset as it's the opposite of what you think
    offset = offset === 0 ? 0 : offset * -1;
    return { 'x-timezone-offset': String(offset) };
  } catch {
    return {};
  }
};

const combineHeaders = (config: HeadersInit, isJson: boolean = true) => {
  const contentTypeHeaders = isJson
    ? {
        'Content-Type': 'application/json'
      }
    : undefined;
  return {
    ...contentTypeHeaders,
    ...getTimeZoneHeaders(),
    ...getTimeZoneOffsetHeaders(),
    ...config
  };
};

const isJson = (headers: Headers) =>
  headers.has('content-type') &&
  headers.get('content-type').indexOf('application/json') > -1;

const handleErrorAsync = async (
  response: Response,
  config: ApiRequestConfig = {}
) => {
  if (config.errorHandlerAsync) {
    await config.errorHandlerAsync(response);
  } else {
    throw await ApiError.create(response);
  }
};

/**
 * This class should not be used directly! It is a helper class
 * used by the public and private APIs.
 *
 * Use the implementations of "ApiBase".
 */
export class API {
  public static async get<T>(
    path: string,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    const url = `${ApiProvider.pathlessApiUrl}/${path}`;
    const res = await fetch(url, {
      method: 'GET',
      ...config,
      headers: { ...combineHeaders(config.headers) }
    });

    if (!isOk(res)) {
      await handleErrorAsync(res, config);
      return;
    }

    if (config.responseHandler) {
      const result = config.responseHandler(res);
      if (result !== undefined) {
        return result;
      }
    }

    return isJson(res.headers) ? await res.json() : await res.text();
  }

  public static async post<T>(
    path: string,
    body: any,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    const url = `${ApiProvider.pathlessApiUrl}/${path}`;
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      ...config,
      headers: { ...combineHeaders(config.headers) }
    });

    if (!isOk(res)) {
      await handleErrorAsync(res, config);
      return;
    }

    if (config.responseHandler) {
      const result = config.responseHandler(res);
      if (result !== undefined) {
        return result;
      }
    }

    return isJson(res.headers) ? await res.json() : await res.text();
  }

  public static async postFormData<T>(
    path: string,
    body: FormData,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    const url = `${ApiProvider.pathlessApiUrl}/${path}`;
    const res = await fetch(url, {
      method: 'POST',
      body,
      ...config,
      headers: { ...combineHeaders(config.headers, false) }
    });

    if (!isOk(res)) {
      await handleErrorAsync(res, config);
      return;
    }

    if (config.responseHandler) {
      const result = config.responseHandler(res);
      if (result !== undefined) {
        return result;
      }
    }

    return isJson(res.headers) ? await res.json() : await res.text();
  }

  public static canSendBeacon(): boolean {
    return window && 'sendBeacon' in window.navigator;
  }

  public static sendBeacon(path: string, body?: any): boolean {
    if (!(window && 'sendBeacon' in window.navigator)) {
      return false;
    }
    const url = `${ApiProvider.pathlessApiUrl}/${path}`;
    return navigator.sendBeacon(
      url,
      body
        ? new Blob([JSON.stringify(body)], { type: 'application/json' })
        : undefined
    );
  }

  public static async put<T>(
    path: string,
    body?: any,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    const url = `${ApiProvider.pathlessApiUrl}/${path}`;
    const res = await fetch(url, {
      method: 'PUT',
      body: !body ? undefined : JSON.stringify(body),
      ...config,
      headers: { ...combineHeaders(config.headers) }
    });

    if (!isOk(res)) {
      await handleErrorAsync(res, config);
      return;
    }

    if (config.responseHandler) {
      const result = config.responseHandler(res);
      if (result !== undefined) {
        return result;
      }
    }

    return isJson(res.headers) ? await res.json() : await res.text();
  }

  public static async delete<T>(
    path: string,
    config: ApiRequestConfig = {}
  ): Promise<void | T> {
    const url = `${ApiProvider.pathlessApiUrl}/${path}`;
    const res = await fetch(url, {
      method: 'DELETE',
      ...config,
      headers: { ...combineHeaders(config.headers) }
    });

    if (!isOk(res)) {
      await handleErrorAsync(res, config);
      return;
    }

    if (config.responseHandler) {
      const result = config.responseHandler(res);
      if (result !== undefined) {
        return result;
      }
    }

    return isJson(res.headers) ? await res.json() : await res.text();
  }
}
