import { asBoolean } from '@/common/utils/BooleanFunctions';

export class Page<T> {
  items: T[] = [];
  list?: T[] = [];
  hasNextPage: boolean = false;
  hasPreviousPage: boolean = false;
  pageNumber: number = 1;
  pageSize: number = 20;
  totalItems: number = 0;
  totalItemsNoFilter: number = 0;
  totalPages: number = 0;
  take: number;
  skip: number;
  hasMoreItems: boolean;

  get hasAnyDataNoFilter() {
    return this.totalItemsNoFilter > 0;
  }

  get hasAnyItems() {
    return this.totalItems > 0;
  }

  constructor(props?: Partial<Page<T>>) {
    props = props || {};
    Object.assign(this, props);
    if (!this.items) this.items = [];
    this.hasMoreItems = asBoolean(props.hasMoreItems);
  }

  public static empty<T>() {
    return new Page<T>();
  }
}
