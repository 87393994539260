import { isServer } from '@/common/utils/NextFunctions';

const apiUrlForServer = (() => {
  const url = process?.env?.API_URL;
  if (url) {
    return url.endsWith('/') ? url.substr(0, url.length - 1) : url;
  }
  return 'http://localhost:5002';
})();

const pathlessApiUrl = isServer()
  ? apiUrlForServer
  : `${window.location.protocol}//${window.location.host}`;

export const ApiProvider = {
  /**
   * The API URL to use based on if the request is server side
   * or client side. We try to reduce the number of traversals when
   * requesting server side so if we don't need to leave the kube cluster
   * then we request directly to the API services.
   *
   * Note: This does not include the base path, i.e. "/api/v1/live"
   */
  pathlessApiUrl
};
