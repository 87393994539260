import { IHasId } from './IHasId';

const isNilOrWhiteSpace = (value?: string | unknown) => {
  return (
    value === null ||
    value === undefined ||
    value.toString().trim().length === 0
  );
};

const GUID_EMPTY_STRING: string = '00000000-0000-0000-0000-000000000000';
const GUID_FORMAT: RegExp =
  /{?([a-z0-9]{8}(?:-[a-z0-9]{4}){3}-[a-z0-9]{12})}?/i;

/**
 * A unique identifier.
 */
export class Guid {
  private readonly id: string;

  /**
   * Creates a new GUID from a string or clones existing guid.
   * @param {String|Guid} id The GUID to create.
   */
  constructor(id: string | Guid) {
    if (id instanceof Guid) {
      this.id = id.valueOf();
      return;
    }

    if (GUID_FORMAT.test(id)) {
      const result = GUID_FORMAT.exec(id);
      if (result) {
        this.id = result[1];
        return;
      }
    }

    throw new Error(`Invalid GUID ${id}`);
  }

  /**
   * Returns the underlying value.
   */
  valueOf(): string {
    return this.id;
  }

  /**
   * Returns if the current GUID is empty.
   */
  isEmpty(): boolean {
    return Guid.isEmpty(this);
  }

  /**
   * Compares if the specified GUID is equal to the current GUID.
   * @param {String|Guid|undefined|null} value The value to compare.
   * @returns True if equal, else false.
   */
  equals(value: string | Guid | undefined | null): boolean {
    try {
      if (!value) {
        return false;
      }
      if (!this.id) {
        return false;
      }
      return this.id === new Guid(value).valueOf();
    } catch (err) {
      return false;
    }
  }

  /**
   * Returns the JSON version of the GUID.
   */
  toJSON(): string {
    return this.id;
  }

  /**
   * Converts the GUID to a formatted string.
   *
   * Formats:
   *   n = 00000000000000000000000000000000
   *   d = 00000000-0000-0000-0000-000000000000 (default)
   *   b = {00000000-0000-0000-0000-000000000000}
   */
  toString(format: string = 'd'): string {
    switch (format.toLowerCase()) {
      case 'n':
        return this.id.replaceAll('-', '');
      case 'b':
        return `{${this.id}}`;
      default:
        return this.id;
    }
  }

  /**
   * Returns an empty GUID.
   */
  static empty(): Guid {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return GUID_EMPTY;
  }

  /**
   * Creates a new GUID.
   */
  static newGuid(): Guid {
    return new Guid(
      `${Guid.s4()}${Guid.s4()}-${Guid.s4()}-${Guid.s4()}-${Guid.s4()}-${Guid.s4()}${Guid.s4()}${Guid.s4()}`
    );
  }

  /**
   * Returns if the specified GUID is empty.
   * @param {String|Guid} id The GUID to test.
   */
  static isEmpty(id: string | Guid): boolean {
    if (!id) {
      return false;
    }
    return Guid.empty().equals(id);
  }

  static areEqual(
    one: string | Guid | undefined,
    two: string | Guid | undefined
  ): boolean {
    if (!one || !two) {
      return !one && !two;
    }
    if (!Guid.isValid(one) || !Guid.isValid(two)) {
      return false;
    }
    return new Guid(one).equals(new Guid(two));
  }

  /**
   * Tests if the specified GUID is valid.
   * @param {String|Guid} id The GUID to test.
   */
  static isValid(id?: string | Guid) {
    if (!id) {
      return false;
    }
    if (id instanceof Guid) {
      return true;
    }
    return GUID_FORMAT.test(id);
  }

  /**
   * Tests if the specified GUID is valid.
   * @param {String|Guid} id The GUID to test.
   */
  static tryParse(id?: string | Guid): [boolean, Guid] {
    if (!id) {
      return [false, Guid.empty()];
    }
    if (!Guid.isValid(id)) {
      return [false, Guid.empty()];
    }
    return [true, new Guid(id)];
  }

  /**
   * Returns the value as a Guid or an undefined if not a valid Guid or undefined.
   * @param value The Guid value or an undefined.
   */
  static valueOrUndefined(value: Guid | string | undefined) {
    return value ? new Guid(value) : undefined;
  }

  /**
   * Returns the value as a Guid or a null if not a valid Guid or null.
   * @param value The Guid value or a null.
   */
  static valueOrNull(value: Guid | string | null) {
    return value ? new Guid(value) : null;
  }

  /**
   * Returns the value as a Guid or an new Guid if not a valid Guid or undefined.
   * @param value The Guid value or an new Guid.
   */
  static valueOrNew(value: Guid | string | undefined) {
    return value ? new Guid(value) : Guid.newGuid();
  }

  /**
   * Returns the value as a Guid or an undefined if not a valid Guid or undefined.
   * @param value The Guid value or an undefined.
   */
  static valueOrEmpty(value: Guid | string | undefined) {
    return value ? new Guid(value) : Guid.empty();
  }

  /**
   * Returns if the specified GUID values are equal.
   * @param val1 The first GUID to compare.
   * @param val2 The second GUID to compare.
   * @returns True if both guids are equal, or both are nil/empty; otherwise false.
   */
  static equals(val1?: string | Guid, val2?: string | Guid): boolean {
    try {
      if (isNilOrWhiteSpace(val1) && isNilOrWhiteSpace(val2)) {
        return true;
      }

      if (isNilOrWhiteSpace(val1) || isNilOrWhiteSpace(val2)) {
        return false;
      }

      return new Guid(val1).equals(val2);
    } catch {
      return false;
    }
  }

  /**
   * Filter function to apply to an array of GUIDs to only get unique values.
   * @example const uniqueGuids = myGuidArray.filter(filterUniques);
   */
  static uniqueFilter(value: Guid, index: number, array: Guid[]) {
    return array.findIndex((x) => x.equals(value)) === index;
  }

  /**
   * Given an array of ordered Ids, return a sorting function that will sort a
   * collection of IHasId items in the same order.
   * Useful for shuffling a set of IDs, storing them in state, then sorting
   * items using those shuffled IDs.
   * @param idsInOrder - The IDs in the order you wish to sort the items in.
   */
  static orderByIdArray(idsInOrder: Guid[]) {
    return (a: IHasId<Guid>, b: IHasId<Guid>) => {
      return (
        idsInOrder.findIndex((x) => x.equals(a.id)) -
        idsInOrder.findIndex((x) => x.equals(b.id))
      );
    };
  }

  private static s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
}

const GUID_EMPTY = new Guid(GUID_EMPTY_STRING);
