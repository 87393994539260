import {
  DefaultMantineColor,
  MantineColorsTuple,
  MantineTheme,
  useMantineTheme
} from '@mantine/core';

import { ExtendedCustomColors } from './_theme/_colors';
import { DefaultTheme, IOtherThemeOverride } from './_theme/_MergedTheme';

export * from './_theme';

declare module '@mantine/core' {
  type Other = IOtherThemeOverride['other'];
  export interface MantineThemeOther extends Other {}
  export interface MantineThemeColorsOverride {
    colors: Record<
      ExtendedCustomColors | DefaultMantineColor,
      MantineColorsTuple
    >;
  }
}

export type Theme = MantineTheme;
export type ThemeColors = MantineTheme['colors'];

export interface IHasTheme {
  theme: Theme;
}

export type WithThemeProps<TProps> = IHasTheme & TProps;

export const KomoColorConstants = {
  surfaceBg: '#FFFFFF',
  surfaceC: '#000000',
  surfaceBd: '#DEE2E6' //gray.3
};
/**
 * @deprecated use className with css modules or style prop instead
 */
const theme = DefaultTheme;
const useTheme = useMantineTheme;
export { theme, useTheme };
